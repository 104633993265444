import React from 'react';
import { Col, Row } from 'react-bootstrap';

const ResultsHeader = ({ currentTitle }) => {
  return (
    <Row>
      <Col>
        <div className="h1 mt-2 text-center">Survey Results</div>
        <div className="h5 text-center">{currentTitle}</div>
      </Col>
    </Row>
  );
};

export default ResultsHeader;
