import React, { useEffect } from 'react';

const ThankYou = () => {
  useEffect(() => {
    const confettiContainer = document.createElement('div');
    document.body.appendChild(confettiContainer);

    const createConfetti = () => {
      const confetti = document.createElement('div');
      confetti.className = 'confetti';
      confetti.style.left = `${Math.random() * 100}vw`;
      confetti.style.animationDelay = `${Math.random() * 5}s`;

      document.body.appendChild(confetti);

      confetti.addEventListener('animationiteration', () => {
        confetti.remove();
        createConfetti();
      });
    };

    for (let i = 0; i < 100; i++) {
      createConfetti();
    }

    setTimeout(() => {
      document.body.removeChild(confettiContainer);
    }, 10000); // Adjust the time as needed
  }, []);

  return (
    <>
      <div className="overlay-text">
        <div className="h1 text-center">🎉 Thank you! 🎉</div>
        <div className="h5 text-center">Your survey was submitted</div>
      </div>
      <style>
        {`
          body {
            margin: 0;
            overflow: hidden;
            background-color: #83a1eb;
          }

          @keyframes confetti {
            0% {
              transform: translateY(0) rotate(0);
              opacity: 0;
            }
            20% {
              opacity: 1;
            }
            100% {
              transform: translateY(500px) rotate(720deg);
              opacity: 0;
            }
          }

          .overlay-text {
            position: absolute;
            top: 20%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2; /* Ensure text is on top of confetti */
            text-align: center;
            color: white;
          }

          .confetti {
            position: absolute;
            width: 8px;
            height: 8px;
            background-color: #ffcc00;
            border-radius: 50%;
            animation: confetti 5s linear infinite;
            opacity: 0;
          }

          .confetti:nth-child(2n) {
            background-color: #ff66b2;
          }

          .confetti:nth-child(3n) {
            background-color: #66b2ff;
          }
        `}
      </style>
    </>
  );
};

export default ThankYou;
