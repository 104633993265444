import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { firestore } from "./Firebase";
import Rating from "react-rating-stars-component";
import { toast, Toaster } from "react-hot-toast";
import ThankYou from "./ThankYou.js";

const Survey = () => {
  const [doctors, setDoctors] = useState([]);
  const [ratings, setRatings] = useState({});
  const [focusedTextarea, setFocusedTextarea] = useState(null);
  const [isSurveySubmitted, setIsSurveySubmitted] = useState(false);
  const [currentDirections, setCurrentDirections] = useState("");
  const [currentMessage, setCurrentMessage] = useState("");
  const [currentLegend, setCurrentLegend] = useState("");

  const handleFocus = (doctorId) => {
    setFocusedTextarea(doctorId);
  };

  const handleBlur = () => {
    setFocusedTextarea(null);
  };

  const handleRatingChange = (doctorId, ratingName, value) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [doctorId]: {
        ...prevRatings[doctorId],
        [ratingName]: value,
        [`${ratingName}NA`]: false,
      },
    }));
  };

  const handleNAClick = (doctorId, ratingName) => {
    setRatings((prevRatings) => {
      const isCurrentlyNA = prevRatings[doctorId]?.[`${ratingName}NA`];
      return {
        ...prevRatings,
        [doctorId]: {
          ...prevRatings[doctorId],
          [ratingName]: isCurrentlyNA ? 0 : "NA",
          [`${ratingName}NA`]: !isCurrentlyNA,
        },
      };
    });
  };

  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  const formattedDateTime = currentDateTime
    .toISOString()
    .slice(0, 19)
    .replace("T", " ");

  const intervalIdRef = useRef(null);

  useEffect(() => {
    const unsubscribe = firestore.collection("admin").onSnapshot((snapshot) => {
      snapshot.docs.forEach((doc) => {
        switch (doc.id) {
          case "directions":
            setCurrentDirections(doc.data().text || "");
            break;
          case "message":
            setCurrentMessage(doc.data().text || "");
            break;
          case "legend":
            setCurrentLegend(doc.data().text || "");
            break;
          default:
            break;
        }
      });
    });

    const unsubscribeDoctors = firestore
      .collection("doctors")
      .orderBy("created_at", "asc")
      .onSnapshot((snapshot) => {
        const firestoreDoctors = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Sort doctors by last name
        const sortedDoctors = firestoreDoctors.sort((a, b) => {
          const aLastName = a.name.trim().split(" ").pop().toLowerCase();
          const bLastName = b.name.trim().split(" ").pop().toLowerCase();
          return aLastName.localeCompare(bLastName);
        });

        const initialRatings = {};
        sortedDoctors.forEach((doctor) => {
          initialRatings[doctor.id] = {
            inpatient: 0,
            outpatient: 0,
            imaging: 0,
            procedures: 0,
            comments: "",
          };
        });

        setDoctors(sortedDoctors); // Use the sorted array
        setRatings(initialRatings);
      });

    intervalIdRef.current = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => {
      clearInterval(intervalIdRef.current);
      unsubscribe();
      unsubscribeDoctors();
    };
  }, []);

  const handleSubmission = async () => {
    try {
      const batch = firestore.batch();
      let isEmptySubmission = false;

      doctors.forEach((doctor) => {
        const inpatient = ratings[doctor.id]?.inpatient;
        const outpatient = ratings[doctor.id]?.outpatient;
        const imaging = ratings[doctor.id]?.imaging;
        const procedures = ratings[doctor.id]?.procedures;
        const comments = ratings[doctor.id]?.comments;

        if (
          inpatient === undefined ||
          outpatient === undefined ||
          imaging === undefined ||
          procedures === undefined ||
          comments === undefined ||
          (inpatient === 0 && inpatient !== "NA") ||
          (outpatient === 0 && outpatient !== "NA") ||
          (imaging === 0 && imaging !== "NA") ||
          (procedures === 0 && procedures !== "NA")
        ) {
          isEmptySubmission = true;
        } else {
          const surveys = firestore.collection("surveys");
          const surveyRef = surveys.doc(formattedDateTime);
          surveyRef.set({});

          const resultsRef = surveyRef.collection("results").doc();
          batch.set(resultsRef, {
            doctorId: doctor.id,
            name: doctor.name,
            inpatient: inpatient === "NA" ? "" : inpatient,
            outpatient: outpatient === "NA" ? "" : outpatient,
            imaging: imaging === "NA" ? "" : imaging,
            procedures: procedures === "NA" ? "" : procedures,
            comments,
            timestamp: formattedDateTime,
          });
        }
      });

      if (isEmptySubmission) {
        toast.error("Please provide all ratings before submitting.");
      } else {
        await batch.commit();
        toast.success("Survey submitted successfully.");

        setIsSurveySubmitted(true);

        const initialRatings = {};
        doctors.forEach((doctor) => {
          initialRatings[doctor.id] = {
            inpatient: 0,
            outpatient: 0,
            imaging: 0,
            procedures: 0,
            comments: "",
          };
        });

        setRatings(initialRatings);
      }
    } catch (error) {
      console.error("Error submitting survey:", error);
    }
  };

  const isRatingEmpty = (doctorId, ratingName) => {
    const rating = ratings[doctorId]?.[ratingName];
    return rating === 0 && rating !== "NA";
  };

  const handleNoExperience = (doctorId) => {
    setRatings((prevRatings) => ({
      ...prevRatings,
      [doctorId]: {
        ...prevRatings[doctorId],
        inpatient: "NA",
        outpatient: "NA",
        imaging: "NA",
        procedures: "NA",
        inpatientNA: true,
        outpatientNA: true,
        imagingNA: true,
        proceduresNA: true,
        comments: prevRatings[doctorId]?.comments || "",
      },
    }));
  };

  return (
    <>
      {!isSurveySubmitted ? (
        <Container className="survey">
          <Row>
            <Toaster />
            <Col>
              <div className="h1 mt-2 text-center">
                Anonymous Feedback Survey
              </div>
              <Row>
                <Col md={{ span: 6, offset: 3 }}>
                  <div className="h6">{currentMessage}</div>
                </Col>
              </Row>
              <Row className="mt-2 pt-1" style={{ backgroundColor: "#dbdada" }}>
                <Col md={{ span: 10, offset: 1 }} className="text-center">
                  <div className="d-flex flex-column align-items-center">
                    <div className="h5 mb-2 fw-bold">Legend</div>
                    <div className="h6 fw-bold text-center">
                      {currentLegend}
                    </div>
                  </div>
                </Col>
              </Row>

              {doctors.map((item, index) => (
                <Row
                  key={item.id}
                  className="mb-2 pb-1"
                  style={{
                    backgroundColor: index % 2 === 0 ? "#f8f9fa" : "#f2f2f2",
                  }}
                >
                  <Col
                    md={6}
                    className="text-start d-lg-flex justify-content-evenly align-items-center"
                  >
                    <div className="d-flex flex-column align-items-center gap-2">
                      <div className="h5">{item.name}</div>
                      <button
                        className="btn btn-sm btn-outline-secondary"
                        onClick={() => handleNoExperience(item.id)}
                        type="button"
                      >
                        No Experience
                      </button>
                    </div>

                    <div>
                      <div className="h6">
                        inpatient -{" "}
                        {ratings[item.id]?.inpatient === "NA"
                          ? "N/A"
                          : ratings[item.id]?.inpatient}
                      </div>
                      <div
                        className="d-flex flex-column align-items-center gap-1 p-2 rounded"
                        style={{
                          border: isRatingEmpty(item.id, "inpatient")
                            ? "2px solid #dc3545"
                            : "none",
                          backgroundColor: isRatingEmpty(item.id, "inpatient")
                            ? "#fff5f5"
                            : "transparent",
                        }}
                      >
                        <Rating
                          key={`${item.id}-${
                            ratings[item.id]?.inpatientNA ? "na" : "rating"
                          }`}
                          count={5}
                          value={
                            ratings[item.id]?.inpatientNA
                              ? 0
                              : ratings[item.id]?.inpatient
                          }
                          onChange={(value) =>
                            handleRatingChange(item.id, "inpatient", value)
                          }
                          size={20}
                          activeColor="#ffd700"
                        />
                        <button
                          className={`btn btn-sm ${
                            ratings[item.id]?.inpatientNA
                              ? "btn-secondary"
                              : "btn-outline-secondary"
                          }`}
                          onClick={() => handleNAClick(item.id, "inpatient")}
                          type="button"
                        >
                          N/A
                        </button>
                      </div>
                    </div>

                    <div>
                      <div className="h6">
                        outpatient -{" "}
                        {ratings[item.id]?.outpatient === "NA"
                          ? "N/A"
                          : ratings[item.id]?.outpatient}
                      </div>
                      <div className="d-flex flex-column align-items-center gap-1">
                        <Rating
                          key={`${item.id}-${
                            ratings[item.id]?.outpatientNA ? "na" : "rating"
                          }`}
                          count={5}
                          value={
                            ratings[item.id]?.outpatientNA
                              ? 0
                              : ratings[item.id]?.outpatient
                          }
                          onChange={(value) =>
                            handleRatingChange(item.id, "outpatient", value)
                          }
                          size={20}
                          activeColor="#ffd700"
                        />
                        <button
                          className={`btn btn-sm ${
                            ratings[item.id]?.outpatientNA
                              ? "btn-secondary"
                              : "btn-outline-secondary"
                          }`}
                          onClick={() => handleNAClick(item.id, "outpatient")}
                          type="button"
                        >
                          N/A
                        </button>
                      </div>
                    </div>
                    <div>
                      <div className="h6">
                        imaging -{" "}
                        {ratings[item.id]?.imaging === "NA"
                          ? "N/A"
                          : ratings[item.id]?.imaging}
                      </div>
                      <div className="d-flex flex-column align-items-center gap-1">
                        <Rating
                          key={`${item.id}-${
                            ratings[item.id]?.imagingNA ? "na" : "rating"
                          }`}
                          count={5}
                          value={
                            ratings[item.id]?.imagingNA
                              ? 0
                              : ratings[item.id]?.imaging
                          }
                          onChange={(value) =>
                            handleRatingChange(item.id, "imaging", value)
                          }
                          size={20}
                          activeColor="#ffd700"
                        />
                        <button
                          className={`btn btn-sm ${
                            ratings[item.id]?.imagingNA
                              ? "btn-secondary"
                              : "btn-outline-secondary"
                          }`}
                          onClick={() => handleNAClick(item.id, "imaging")}
                          type="button"
                        >
                          N/A
                        </button>
                      </div>
                    </div>
                    <div>
                      <div className="h6">
                        procedures -{" "}
                        {ratings[item.id]?.procedures === "NA"
                          ? "N/A"
                          : ratings[item.id]?.procedures}
                      </div>
                      <div className="d-flex flex-column align-items-center gap-1">
                        <Rating
                          key={`${item.id}-${
                            ratings[item.id]?.proceduresNA ? "na" : "rating"
                          }`}
                          count={5}
                          value={
                            ratings[item.id]?.proceduresNA
                              ? 0
                              : ratings[item.id]?.procedures
                          }
                          onChange={(value) =>
                            handleRatingChange(item.id, "procedures", value)
                          }
                          size={20}
                          activeColor="#ffd700"
                        />
                        <button
                          className={`btn btn-sm ${
                            ratings[item.id]?.proceduresNA
                              ? "btn-secondary"
                              : "btn-outline-secondary"
                          }`}
                          onClick={() => handleNAClick(item.id, "procedures")}
                          type="button"
                        >
                          N/A
                        </button>
                      </div>
                    </div>
                  </Col>

                  <Col md={6}>
                    <textarea
                      className="form-control h-100"
                      placeholder={
                        focusedTextarea === item.id ? "" : "comments"
                      }
                      onFocus={() => handleFocus(item.id)}
                      onBlur={handleBlur}
                      value={ratings[item.id]?.comments || ""}
                      onChange={(e) =>
                        handleRatingChange(item.id, "comments", e.target.value)
                      }
                      style={{ minHeight: "150px", width: "100%" }}
                    ></textarea>
                  </Col>
                </Row>
              ))}
              <button
                className="btn btn-primary"
                type="button"
                onClick={handleSubmission}
              >
                Submit
              </button>
            </Col>
          </Row>
        </Container>
      ) : (
        <Container className="completed">
          <ThankYou />
        </Container>
      )}
    </>
  );
};

export default Survey;
