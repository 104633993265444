import React, { useState, useEffect } from "react";
import { firestore } from "./Firebase";
import { Button } from "react-bootstrap";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faDeleteLeft } from "@fortawesome/free-solid-svg-icons";

const AdminMessage = () => {
  const [message, setMessage] = useState("");

  useEffect(() => {
    const unsubscribe = firestore
      .collection("admin")
      .doc("message")
      .onSnapshot((doc) => {
        if (doc.exists) {
          setMessage(doc.data().text || "");
        }
      });

    return () => unsubscribe();
  }, []);

  const handleSave = async () => {
    try {
      await firestore.collection("admin").doc("message").set({
        text: message,
      });
      toast.success("Message saved successfully");
    } catch (error) {
      toast.error("Error saving message");
      console.error("Error saving message:", error);
    }
  };

  const handleClear = async () => {
    try {
      await firestore.collection("admin").doc("message").set({
        text: "",
      });
      setMessage("");
      toast.success("Message cleared");
    } catch (error) {
      toast.error("Error clearing message");
    }
  };

  return (
    <div className="d-flex mb-1">
      <div className="flex-grow-1 my-1 ms-1">
        <textarea
          className="form-control"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          rows="5"
        />
      </div>
      <div className="d-flex flex-column">
        <Button
          variant="primary"
          className="px-3 me-1 mb-1"
          onClick={handleSave}
        >
          <FontAwesomeIcon icon={faSave} />
        </Button>
        <Button variant="danger" className="px-3 me-1" onClick={handleClear}>
          <FontAwesomeIcon icon={faDeleteLeft} />
        </Button>
      </div>
    </div>
  );
};

export default AdminMessage;
