import React from "react";
import { Button } from "react-bootstrap";
import * as XLSX from "xlsx";

const ResultsCSV = ({ currentTitle }) => {
  const handleDownload = () => {
    const table = document.getElementById("csvTable");
    if (!table) return;

    // Convert table to worksheet
    const ws = XLSX.utils.table_to_sheet(table);

    // Create workbook and add worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Survey Results");

    // Generate filename with current title and date
    const filename = `${currentTitle}_${
      new Date().toISOString().split("T")[0]
    }.xlsx`;

    // Save file
    XLSX.writeFile(wb, filename);
  };

  return (
    <div className="d-flex justify-content-end mb-2">
      <Button variant="success" onClick={handleDownload}>
        Download Results
      </Button>
    </div>
  );
};

export default ResultsCSV;
