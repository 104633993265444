import React, { useEffect, useRef, useState } from "react";
import { firestore } from "./Firebase";
import toast, { Toaster } from "react-hot-toast";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import AdminDirections from "./AdminDirections";
import AdminMessage from "./AdminMessage";
import AdminLegend from "./AdminLegend";
import PasswordProtected from "./PasswordProtected";

const Admin = () => {
  const [doctorName, setDoctorName] = useState("");
  const [doctors, setDoctors] = useState([]);
  const [show, setShow] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const inputRef = useRef(null);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setEditingIndex(null);
  };
  const handleBlur = (id, newName) => {
    if (editingIndex !== null) {
      editDoctorName(id, newName);
      setEditingIndex(null);
    }
  };

  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  const formattedDateTime = currentDateTime
    .toISOString()
    .slice(0, 19)
    .replace("T", " ");

  const addDoctor = async () => {
    if (doctorName.trim() !== "") {
      await firestore.collection("doctors").add({
        name: doctorName,
        created_at: formattedDateTime,
      });
      toast.success(doctorName + " added successfully.");
      setDoctorName("");
      handleClose();
    } else {
      inputRef.current.focus();
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      addDoctor();
    }
  };

  useEffect(() => {
    if (show) {
      inputRef.current.focus();
    }

    const unsubscribe = firestore
      .collection("doctors")
      .orderBy("created_at", "asc")
      .onSnapshot((snapshot) => {
        const firestoreDoctors = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Sort doctors by last name
        const sortedDoctors = firestoreDoctors.sort((a, b) => {
          const aLastName = a.name.trim().split(" ").pop().toLowerCase();
          const bLastName = b.name.trim().split(" ").pop().toLowerCase();
          return aLastName.localeCompare(bLastName);
        });

        setDoctors(sortedDoctors);
      });

    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => {
      clearInterval(intervalId);
      unsubscribe();
    };
  }, [show]);

  const handleDelete = async (id, name = "") => {
    await firestore.collection("doctors").doc(id).delete();
    toast.success(name + " deleted successfully");
  };

  const editDoctorName = async (id, newName) => {
    try {
      await firestore.collection("doctors").doc(id).update({
        name: newName,
      });
      toast.success("Doctor name updated successfully");
      setEditingIndex(null);
    } catch (error) {
      console.error("Error updating doctor name:", error);
      toast.error("Error updating doctor name");
    }
  };

  return (
    <PasswordProtected>
      <div className="admin-container">
        <Container className="admin">
          <Toaster />
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <div className="doctor-list">
                <div className="h1 mt-2">Doctor List</div>
                {doctors.map((item, index) => (
                  <div key={item.id} className="doctor-item d-flex mb-1">
                    <div className="flex-grow-1 my-1 ms-1">
                      <input
                        type="text"
                        className="form-control"
                        style={{
                          backgroundColor: `${
                            editingIndex === index ? "#ff6600" : ""
                          }`,
                        }}
                        value={item.name}
                        onChange={(e) => {
                          const updatedDoctors = [...doctors];
                          updatedDoctors[index].name = e.target.value;
                          setDoctors(updatedDoctors);
                          setEditingIndex(index);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" || e.key === "Tab") {
                            e.preventDefault();
                            editDoctorName(item.id, item.name);
                            setEditingIndex(null);
                          }
                        }}
                        onBlur={() => handleBlur(item.id, item.name)}
                      />
                    </div>
                    <div>
                      <Button
                        variant="danger"
                        className="mt-1 px-3 me-1"
                        onClick={() => handleDelete(item.id, item.name)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </div>
                  </div>
                ))}
                <div className="add-doctor d-flex justify-content-around">
                  <Button
                    variant="primary"
                    className="mt-1 px-5"
                    onClick={handleShow}
                  >
                    add doctor
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <div className="h1 mt-2">Directions</div>
              <AdminDirections />
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <div className="h1 mt-2">Instructions</div>
              <AdminMessage />
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <div className="h1 mt-2">Legend</div>
              <AdminLegend />
            </Col>
          </Row>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Add Doctor</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="name"
                  value={doctorName}
                  onChange={(e) => setDoctorName(e.target.value)}
                  onKeyDown={handleKeyPress}
                  ref={inputRef}
                />
                <label htmlFor="name">name</label>
              </div>
              <Button variant="primary" onClick={addDoctor}>
                Add Doctor
              </Button>
            </Modal.Body>
          </Modal>
        </Container>
      </div>
    </PasswordProtected>
  );
};

export default Admin;
