import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form, Button, Modal } from "react-bootstrap";
import { firestore } from "./Firebase";
import ResultsHeader from "./ResultsHeader";
import ResultsNav from "./ResultsNav";
import ResultsCSV from "./ResultsCSV";
import PasswordProtected from "./PasswordProtected";
import { toast } from "react-hot-toast";
import * as XLSX from "xlsx";

const Results = () => {
  const [isSurvey, setIsSurvey] = useState(true);
  const [isDoctor, setIsDoctor] = useState(false);
  const [activeDoctor, setActiveDoctor] = useState("");
  const [activeSurvey, setActiveSurvey] = useState("");
  const [doctors, setDoctors] = useState([]);
  const [surveys, setSurveys] = useState([]);
  const [selectedSurvey, setSelectedSurvey] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState([]);
  const [currentTitle, setCurrentTitle] = useState("Select results");
  const [lastSelectedSurveyId, setLastSelectedSurveyId] = useState("");
  const [lastSelectedDoctorName, setLastSelectedDoctorName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isFiltered, setIsFiltered] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [surveyToDelete, setSurveyToDelete] = useState(null);

  const classActiveSurvey = (surveyId) =>
    activeSurvey === surveyId ? "bg-warning" : "bg-primary";
  const classActiveDoctor = (doctorId) =>
    activeDoctor === doctorId ? "bg-warning" : "bg-primary";

  const handleActiveSurvey = async (surveyId) => {
    // If clicking the same survey again, deselect it
    if (activeSurvey === surveyId) {
      setActiveSurvey("");
      setLastSelectedSurveyId("");
      setCurrentTitle("Select results");
      setSelectedSurvey([]);
      return;
    }

    // Otherwise, proceed with selecting the survey
    setActiveSurvey(surveyId);

    const surveyDataRef = firestore
      .collection("surveys")
      .doc(surveyId)
      .collection("results");

    let query = surveyDataRef;

    // Get all results first
    const allResults = await surveyDataRef.get();
    console.log("===== DEBUG =====");
    console.log(`Total unfiltered results: ${allResults.size}`);

    // Log a few sample timestamps
    allResults.docs.slice(0, 3).forEach((doc, i) => {
      console.log(`Sample ${i + 1} timestamp:`, doc.data().timestamp);
    });

    // Add date filtering if dates are selected
    if (isFiltered && startDate && endDate) {
      const startDateTime = startDate + " 00:00:00";
      const endDateTime = endDate + " 23:59:59";

      console.log("Date range:", {
        start: startDateTime,
        end: endDateTime,
        startDate,
        endDate,
      });

      query = query
        .where("timestamp", ">=", startDateTime)
        .where("timestamp", "<=", endDateTime);
    }

    const surveyDataSnapshot = await query.get();
    console.log(`Filtered results: ${surveyDataSnapshot.size}`);

    if (surveyDataSnapshot.size === 0) {
      console.log("WARNING: No results found in date range");
    }
    console.log("================");

    const surveyDataArray = surveyDataSnapshot.docs.map((doc) => doc.data());
    setSelectedSurvey(surveyDataArray);

    setLastSelectedSurveyId(surveyId);
    setCurrentTitle(surveyId);
  };

  useEffect(() => {
    const unsubscribeSurveys = firestore
      .collection("surveys")
      .onSnapshot((snapshot) => {
        const firestoreSurveys = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSurveys(firestoreSurveys);
      });

    const unsubscribeDoctors = firestore
      .collection("doctors")
      .orderBy("created_at", "asc")
      .onSnapshot((snapshot) => {
        const firestoreDoctors = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setDoctors(firestoreDoctors);
      });

    return () => {
      unsubscribeDoctors();
      unsubscribeSurveys();
    };
  }, []);

  const handleBySurveyClick = () => {
    setIsSurvey(true);
    setIsDoctor(false);
    if (lastSelectedSurveyId) {
      setCurrentTitle(lastSelectedSurveyId);
    } else {
      setCurrentTitle("Select results");
    }
  };

  const handleByDoctorClick = () => {
    setIsSurvey(false);
    setIsDoctor(true);
    if (lastSelectedDoctorName) {
      setCurrentTitle(lastSelectedDoctorName);
    } else {
      setCurrentTitle("Select results");
    }
  };

  const classIsSurvey = isSurvey ? "active bg-primary text-white" : "pe-auto";
  const classIsDoctor = isDoctor ? "active bg-primary text-white" : "pe-auto";

  const handleActiveDoctor = async (doctorId, name = "") => {
    // If clicking the same doctor again, deselect it
    if (activeDoctor === doctorId) {
      setActiveDoctor("");
      setLastSelectedDoctorName("");
      setCurrentTitle("Select results");
      setSelectedDoctor([]);
      return;
    }

    setActiveDoctor(doctorId);
    setLastSelectedDoctorName(name);
    setCurrentTitle(name);

    try {
      // Get all surveys and their results for this doctor
      const surveysSnapshot = await firestore.collection("surveys").get();
      let totalResults = [];

      // For each survey, get its results for this doctor
      const promises = surveysSnapshot.docs.map(async (surveyDoc) => {
        const resultsRef = surveyDoc.ref
          .collection("results")
          .where("doctorId", "==", doctorId);

        const resultsSnapshot = await resultsRef.get();
        const results = resultsSnapshot.docs.map((doc) => ({
          ...doc.data(),
          surveyId: surveyDoc.id,
        }));

        return results;
      });

      const allResults = await Promise.all(promises);
      totalResults = allResults.flat();
      setSelectedDoctor(totalResults);
    } catch (error) {
      console.error("Error getting doctor results:", error);
    }
  };

  const handleGlobalDateFilter = async () => {
    setIsFiltered(true);
    const startDateTime = startDate + " 00:00:00";
    const endDateTime = endDate + " 23:59:59";

    console.log(
      "Filtering all surveys between:",
      startDateTime,
      "and",
      endDateTime
    );

    try {
      // Get all surveys
      const surveysSnapshot = await firestore.collection("surveys").get();
      let totalResults = [];

      // For each survey, get its results within the date range
      const promises = surveysSnapshot.docs.map(async (surveyDoc) => {
        const resultsRef = surveyDoc.ref
          .collection("results")
          .where("timestamp", ">=", startDateTime)
          .where("timestamp", "<=", endDateTime);

        const resultsSnapshot = await resultsRef.get();
        const results = resultsSnapshot.docs.map((doc) => ({
          ...doc.data(),
          surveyId: surveyDoc.id,
        }));

        return results;
      });

      const allResults = await Promise.all(promises);
      totalResults = allResults.flat();

      console.log(
        `Found ${totalResults.length} total submissions in date range`
      );
      setSelectedSurvey(totalResults);
    } catch (error) {
      console.error("Error filtering all surveys:", error);
    }
  };

  const handleDateFilter = () => {
    console.log("=== FILTER DEBUG ===");
    console.log("Start Date:", startDate);
    console.log("End Date:", endDate);
    console.log("Is Survey View:", isSurvey);
    console.log("===================");

    if (isSurvey) {
      handleGlobalDateFilter();
    } else if (isDoctor) {
      handleDoctorDateFilter();
    }
  };

  const handleDoctorDateFilter = async () => {
    if (!activeDoctor) return;

    setIsFiltered(true);
    const startDateTime = startDate + " 00:00:00";
    const endDateTime = endDate + " 23:59:59";

    console.log("Doctor Filter - Start:", startDateTime);
    console.log("Doctor Filter - End:", endDateTime);
    console.log("Active Doctor:", activeDoctor);

    try {
      // Get all surveys
      const surveysSnapshot = await firestore.collection("surveys").get();
      let totalResults = [];

      // For each survey, get its results for this doctor
      for (const surveyDoc of surveysSnapshot.docs) {
        const resultsRef = surveyDoc.ref
          .collection("results")
          .where("doctorId", "==", activeDoctor);

        const resultsSnapshot = await resultsRef.get();

        // Filter by date in memory
        const results = resultsSnapshot.docs
          .map((doc) => ({
            ...doc.data(),
            surveyId: surveyDoc.id,
          }))
          .filter(
            (result) =>
              result.timestamp >= startDateTime &&
              result.timestamp <= endDateTime
          );

        totalResults = [...totalResults, ...results];
      }

      console.log(`Total filtered results: ${totalResults.length}`);
      setSelectedDoctor(totalResults);
    } catch (error) {
      console.error("Error filtering doctor results:", error);
      toast.error("Error filtering results");
    }
  };

  const clearDateFilter = () => {
    setStartDate("");
    setEndDate("");
    setIsFiltered(false);
    // Rerun the active query without filters
    if (isSurvey && activeSurvey) {
      handleActiveSurvey(activeSurvey);
    } else if (isDoctor && activeDoctor) {
      handleActiveDoctor(activeDoctor, lastSelectedDoctorName);
    }
  };

  const countUniqueSurveys = (results) => {
    if (!results.length) return 0;

    // For survey view, count unique survey submissions
    if (isSurvey) {
      // Each unique combination of surveyId and timestamp represents one survey submission
      const uniqueSubmissions = new Set(
        results.map((result) => `${result.surveyId}_${result.timestamp}`)
      );
      return uniqueSubmissions.size;
    }

    // For doctor view, count unique surveyIds
    const uniqueSurveys = new Set(results.map((result) => result.surveyId));
    return uniqueSurveys.size;
  };

  const handleDeleteClick = (surveyId) => {
    setSurveyToDelete(surveyId);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      if (!surveyToDelete) return;

      // Delete the results subcollection first
      const resultsRef = firestore
        .collection("surveys")
        .doc(surveyToDelete)
        .collection("results");

      const resultsSnapshot = await resultsRef.get();
      const batch = firestore.batch();
      resultsSnapshot.docs.forEach((doc) => {
        batch.delete(doc.ref);
      });
      await batch.commit();

      // Then delete the survey document
      await firestore.collection("surveys").doc(surveyToDelete).delete();

      // Reset states
      setShowDeleteModal(false);
      setSurveyToDelete(null);
      setActiveSurvey("");
      setSelectedSurvey([]);
      setCurrentTitle("Select results");

      toast.success("Survey deleted successfully");
    } catch (error) {
      console.error("Error deleting survey:", error);
      toast.error("Error deleting survey");
    }
  };

  const handleDownloadAllCSV = () => {
    if (!selectedSurvey.length) return;

    // Create data array with headers
    const data = [
      [
        "Survey ID",
        "Timestamp",
        "Name",
        "Inpatient",
        "Outpatient",
        "Imaging",
        "Procedures",
        "Comments",
      ],
      // Add data rows
      ...selectedSurvey.map((item) => [
        item.surveyId,
        item.timestamp,
        item.name || "",
        item.inpatient || "",
        item.outpatient || "",
        item.imaging || "",
        item.procedures || "",
        item.comments || "",
      ]),
    ];

    // Create workbook and worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(data);

    // Add worksheet to workbook
    XLSX.utils.book_append_sheet(wb, ws, "Survey Results");

    // Generate filename with date range
    const filename = `survey_results_${startDate}_to_${endDate}.xlsx`;

    // Save file
    XLSX.writeFile(wb, filename);
  };

  // Update the sorting function to handle last names
  const sortedResults = [...selectedSurvey].sort((a, b) => {
    // Extract last names (assuming names are in "First Last" format)
    const getLastName = (name) => {
      if (!name) return "";
      const parts = name.split(" ");
      return parts[parts.length - 1].toLowerCase();
    };

    const lastNameA = getLastName(a.name);
    const lastNameB = getLastName(b.name);

    return lastNameA.localeCompare(lastNameB);
  });

  // Add sorting for doctor view results
  const sortedDoctorResults = [...selectedDoctor].sort((a, b) => {
    return a.timestamp.localeCompare(b.timestamp);
  });

  const setYearFilter = (year) => {
    setStartDate(`${year}-01-01`);
    setEndDate(`${year}-12-31`);
    // Trigger the appropriate filter based on view
    if (isSurvey) {
      handleGlobalDateFilter();
    } else if (isDoctor) {
      handleDoctorDateFilter();
    }
    setIsFiltered(true);
  };

  return (
    <PasswordProtected>
      <div className="results-container">
        <ResultsHeader currentTitle={currentTitle} />
        <Row className="mb-3">
          <Col md={8} lg={6} xl={5}>
            <div className="d-flex align-items-center">
              <div className="date-filter d-flex flex-column align-items-center gap-2 bg-light p-2 rounded shadow-sm">
                {/* First row: All controls */}
                <div className="d-flex align-items-center gap-2">
                  <div className="d-flex flex-column align-items-center">
                    <div className="d-flex align-items-center gap-2">
                      <small className="text-muted me-2">Filter by date:</small>
                      <Form.Control
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        placeholder="Start Date"
                        size="sm"
                      />
                      <span className="text-muted">to</span>
                      <Form.Control
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        placeholder="End Date"
                        size="sm"
                      />
                    </div>

                    {/* Year filter buttons centered under date inputs - now shown in both views */}
                    <div className="d-flex justify-content-center gap-2 mt-2">
                      <Button
                        variant="outline-secondary"
                        size="sm"
                        onClick={() => setYearFilter("2024")}
                      >
                        All 2024
                      </Button>
                      <Button
                        variant="outline-secondary"
                        size="sm"
                        onClick={() => setYearFilter("2025")}
                      >
                        All 2025
                      </Button>
                      <Button
                        variant="outline-secondary"
                        size="sm"
                        onClick={() => setYearFilter("2026")}
                      >
                        All 2026
                      </Button>
                    </div>
                  </div>

                  <Button
                    variant="primary"
                    onClick={handleDateFilter}
                    disabled={
                      !startDate || !endDate || (isDoctor && !activeDoctor)
                    }
                    size="sm"
                    title={
                      isDoctor && !activeDoctor
                        ? "Please select a doctor first"
                        : ""
                    }
                  >
                    Filter
                  </Button>
                  {isFiltered && (
                    <>
                      <Button
                        variant="secondary"
                        onClick={clearDateFilter}
                        size="sm"
                      >
                        Clear
                      </Button>
                      <small className="text-muted">
                        (
                        {countUniqueSurveys(
                          isSurvey ? selectedSurvey : selectedDoctor
                        )}{" "}
                        {isSurvey
                          ? countUniqueSurveys(selectedSurvey) === 1
                            ? "submission"
                            : "submissions"
                          : countUniqueSurveys(selectedDoctor) === 1
                          ? "survey"
                          : "surveys"}{" "}
                        completed)
                      </small>
                      {isFiltered && selectedSurvey.length > 0 && isSurvey && (
                        <Button
                          variant="success"
                          size="sm"
                          onClick={handleDownloadAllCSV}
                          title="Download all filtered results as CSV"
                        >
                          Download All
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </div>
              {activeSurvey && (
                <Button
                  variant="danger"
                  size="sm"
                  className="ms-3"
                  onClick={() => handleDeleteClick(activeSurvey)}
                >
                  Delete Survey
                </Button>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <div className="d-flex flex-wrap">
            <ResultsNav
              isSurvey={isSurvey}
              classIsSurvey={classIsSurvey}
              classIsDoctor={classIsDoctor}
              handleBySurveyClick={handleBySurveyClick}
              handleByDoctorClick={handleByDoctorClick}
              surveys={surveys}
              doctors={doctors}
              classActiveSurvey={classActiveSurvey}
              classActiveDoctor={classActiveDoctor}
              handleActiveSurvey={handleActiveSurvey}
              setLastSelectedSurveyId={setLastSelectedSurveyId}
              handleActiveDoctor={handleActiveDoctor}
              setLastSelectedDoctorName={setLastSelectedDoctorName}
            />
            <Col className="flex-grow-1 ms-3">
              <ResultsCSV currentTitle={currentTitle} />
              <table id="csvTable" className="table table-striped table-hover">
                <thead>
                  <tr>
                    <th>{currentTitle}</th>
                    <th>inpatient</th>
                    <th>outpatient</th>
                    <th>imaging</th>
                    <th>procedures</th>
                    <th>comments</th>
                  </tr>
                </thead>
                {isSurvey ? (
                  <tbody>
                    {sortedResults.length > 0 ? (
                      sortedResults.map((item, index) => {
                        return item ? (
                          <tr key={index}>
                            <td>{item.name || ""}</td>
                            <td>{item.inpatient || ""}</td>
                            <td>{item.outpatient || ""}</td>
                            <td>{item.imaging || ""}</td>
                            <td>{item.procedures || ""}</td>
                            <td>{item.comments || ""}</td>
                          </tr>
                        ) : null;
                      })
                    ) : (
                      <tr>
                        <td className="text-center" colSpan={6}>
                          Select survey on the left.
                        </td>
                      </tr>
                    )}
                  </tbody>
                ) : (
                  <tbody>
                    {selectedDoctor.length > 0 ? (
                      <>
                        {sortedDoctorResults.map((item, index) => (
                          <tr key={index}>
                            <td>{item.surveyId || ""}</td>
                            <td>{item.inpatient || ""}</td>
                            <td>{item.outpatient || ""}</td>
                            <td>{item.imaging || ""}</td>
                            <td>{item.procedures || ""}</td>
                            <td>{item.comments || ""}</td>
                          </tr>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td className="text-center" colSpan={6}>
                          Select doctor on the left.
                        </td>
                      </tr>
                    )}
                  </tbody>
                )}
              </table>
            </Col>
          </div>
        </Row>
        <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Survey</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete this survey? This action cannot be
            undone.
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setShowDeleteModal(false)}
            >
              Cancel
            </Button>
            <Button variant="danger" onClick={handleDeleteConfirm}>
              Yes, Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </PasswordProtected>
  );
};

export default Results;
